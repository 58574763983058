import {ConfiguratorManagerContract} from "../../core/facade/configurator-manager.contract";
import {ConfiguratorManagerBase} from "../../core/facade/configurator-manager-base.service";
import {ProductConfigurationService} from "../../core/facade/product-configuration.service";
import {combineLatest, Observable} from "rxjs";
import {ProductConfigStepType, ProductConfiguratorButton} from "../../core/models/product-configuration.models";
import {map} from "rxjs/operators";
import {
  AutomotiveLogoSelection,
  ConfigurationSelection, MatStyleSelection,
  ProductConfigStepSelection
} from "../../core/store/product-configuration.state";
import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class AutomotiveLogoManagerService implements ConfiguratorManagerContract {

  private nextButton$ = combineLatest([
    this.managerBase.nextButton$,
    this.productConfigService.getCurrentSelection()
  ]).pipe(
    map(([ btn, selection ]) => {
      const logoSelection = selection.selections
        ?.find(s => s.stepType === ProductConfigStepType.AUTOMOTIVE_LOGO) as AutomotiveLogoSelection;
      btn.disabled = !logoSelection;
      return btn;
    })
  );

  private saveButton$ = combineLatest([
    this.managerBase.saveButton$,
    this.productConfigService.getCurrentSelection()
  ]).pipe(
    map(([btn, selection]) => {
      const logoSelection = selection.selections
        ?.find(s => s.stepType === ProductConfigStepType.AUTOMOTIVE_LOGO) as AutomotiveLogoSelection;
      const matSelection = selection.selections
        ?.find(s => s.stepType === ProductConfigStepType.MAT_STYLES) as MatStyleSelection;
      if (!matSelection) {
        btn.disabled = true;
      } else if (logoSelection?.logo === undefined) {
        btn.disabled = false;
      } else if (logoSelection?.selectedMatStyles && logoSelection?.selectedMatStyles.length > 0) {
        btn.disabled = false;
      } else {
        btn.disabled = true;
      }
      return btn;
    })
  )

  private buttons$ = combineLatest([
    this.managerBase.backButton$,
    this.nextButton$,
    this.saveButton$
  ]);

  constructor(private managerBase: ConfiguratorManagerBase,
              private productConfigService: ProductConfigurationService) {
  }

  getButtons(): Observable<ProductConfiguratorButton[]> {
    return this.buttons$;
  }

  getCartPayloadPart(selection: ConfigurationSelection, i: number): string[] {
    const automativeLogo = selection.selections?.find(s => s.stepType === ProductConfigStepType.AUTOMOTIVE_LOGO) as AutomotiveLogoSelection;
    if (!automativeLogo) {
      return [];
    }
    return [ `automotiveLogo::${ automativeLogo.logo.logoCode }::basePartNumbers:${ [automativeLogo.selectedMatStyles.map(s => `${s.sku}|${s.basePartNumber}`)] }`];
  }

  getPrice(stepSelection: ProductConfigStepSelection) {
    const logoSelection = stepSelection as AutomotiveLogoSelection;
    if (logoSelection?.selectedMatStyles?.length > 0) {
      return {
        price: logoSelection?.logo?.price?.value * logoSelection.selectedMatStyles.length,
        priceWithDiscount: logoSelection?.logo?.priceWithDiscounts?.value * logoSelection.selectedMatStyles.length,
      }
    } else {
      return {
        price: 0,
        priceWithDiscount: 0
      }
    }
  }
}
