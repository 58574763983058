import { combineLatest, Observable } from "rxjs";
import { ConfiguratorManagerBase } from "../../core/facade/configurator-manager-base.service";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ConfiguratorManagerContract } from "../../core/facade/configurator-manager.contract";
import { ConfigurationSelection, MatStyleSelection, ProductConfigStepSelection, ProductLineSelection } from "../../core/store/product-configuration.state";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { ProductConfiguratorStepsService } from "../../core/facade/product-configurator-steps.service";
import { ProductConfigStepType, ProductConfiguratorButton, ProductLine } from "../../core/models/product-configuration.models";
import { TranslationService } from "@spartacus/core";

@Injectable({ providedIn: 'root' })
export class MatStyleManagerService implements ConfiguratorManagerContract {

  private selectedMatStyles$ = this.productConfigService.getConfigSelection()
  .pipe(
    map(selection => selection.selections
      ?.filter(s => s.stepType === ProductConfigStepType.MAT_STYLES)
    )
  );

    private nextButton$ = combineLatest([
      this.managerBase.nextButton$,
      this.productConfigService.getCurrentSelection()
    ]).pipe(
      map(([ btn, selection ]) => {
        const matStyleSelection = selection.selections
          ?.find(s => s.stepType === ProductConfigStepType.MAT_STYLES) as MatStyleSelection;
        btn.disabled = !matStyleSelection;
        return btn;
      })
    );
  
    private saveButton$ = combineLatest([
      this.managerBase.saveButton$,
      this.selectedMatStyles$
    ]).pipe(
      map(([ button, matStyles ]) => {
        button.disabled = !matStyles?.length;
        return button;
      })
    );
  

    private buttons$ = combineLatest([
      this.managerBase.backButton$,
      this.nextButton$,
      this.saveButton$
    ]);

  constructor(private managerBase: ConfiguratorManagerBase,
              private productConfigService: ProductConfigurationService,
              private stepService: ProductConfiguratorStepsService,
              private translation: TranslationService) {
  }

  getButtons(): Observable<ProductConfiguratorButton[]> {
    return this.buttons$;
  }

  getCartPayloadPart(selection: ConfigurationSelection, i: number): string[] {
    const matStyle = selection.selections[ i ] as MatStyleSelection;
    if (!matStyle) {
      return [];
    }
    return [ `basePartNumber::${ matStyle.option.sku }|${ matStyle.option.basePartNumber }` ];
  }

  getPrice(stepSelection: ProductConfigStepSelection) {
    const line = stepSelection as ProductLineSelection;
    return {
      price: line.option?.price?.value ?? 0,
      priceWithDiscount: line.option?.priceWithDiscount?.value ?? 0,
    };
  }

}
