import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { map, shareReplay } from "rxjs/operators";
import { Price } from "@spartacus/core";
import { BehaviorSubject, combineLatest } from "rxjs";
import { MatStyle, MatStyleOption, ProductConfigStepType } from "../../core/models/product-configuration.models";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { MatStyleSelection } from "../../core/store/product-configuration.state";
import { STEP_INDEX } from "../../dialog/product-config-dialog/product-config-dialog.component";

@Component({
  selector: 'mat-style',
  templateUrl: './mat-style.component.html',
  styleUrls: ['./mat-style.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MatStyleComponent {
  price$ = new BehaviorSubject<Price>(undefined);

  matStyle$ = this.service.getConfigurationForCurrentProduct().pipe(
    map(
      (config) =>
        config.steps?.find(
          (s) => s.stepType === ProductConfigStepType.MAT_STYLES
        ) as MatStyle
    ),
    shareReplay(1)
  );

  line$ = combineLatest([this.matStyle$, this.service.getConfigSelection()])
  .pipe(
    map(([matStyle, config]) => {
      const selectedMatStyles = config?.selections?.filter((s): s is MatStyleSelection =>
        s.stepType === ProductConfigStepType.MAT_STYLES && 
        matStyle.options?.some(o => (s as MatStyleSelection).option.basePartNumber === o.basePartNumber)
      ) as MatStyleSelection[];

      return {
        matStyle,
        selectedMatStyles,
      };
    }),
    shareReplay(1)
  );

  constructor(
    private service: ProductConfigurationService,
    @Inject(STEP_INDEX) public step: number
  ) {}

  selectItem(matStyle: MatStyleOption) {
    this.service.toggleMatStyle(matStyle);
  }

  isSelected(basePartNumber: string, selectedMatStyles: MatStyleSelection[]) : boolean {
    return selectedMatStyles?.some(s => basePartNumber === s.option.basePartNumber);
  }
}
