import { Injectable } from "@angular/core";
import { ActiveCartService } from "@spartacus/cart/base/core";
import {OCC_CART_ID_CURRENT, OccEndpointsService, UserIdService} from "@spartacus/core";
import {MultiCartFacade, OrderEntry} from "@spartacus/cart/base/root";
import {take, withLatestFrom} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class CustomActiveCartService extends ActiveCartService {

  constructor(
    protected occEndpoints: OccEndpointsService,
    private http: HttpClient,
    protected override multiCartFacade: MultiCartFacade,
    protected override userIdService: UserIdService,
  ) {
    super(multiCartFacade,userIdService);
  }

  protected override loadOrMerge(
    cartId: string,
    userId: string,
    previousUserId: string
  ): void {
    if (cartId === OCC_CART_ID_CURRENT) {
      this.multiCartFacade.loadCart({
        userId,
        cartId,
        extraData: {
          active: true,
        },
      });
    } else {
      this.multiCartFacade.mergeToCurrentCart({
        userId,
        cartId,
        extraData: {
          active: true,
        },
      });
    }
  }

  customRemoveEntry(entry: OrderEntry, childEntryNumber?: number) {
    // remove child entry
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.activeCartId$
      .pipe(withLatestFrom(this.userIdService.getUserId()), take(1))
      .subscribe(([cartId, userId]) => {
        let url = this.occEndpoints.buildUrl(`/users/${userId}/carts/${cartId}/entries/remove/${entry.entryNumber}`);
        if (childEntryNumber) {
          url = this.occEndpoints.buildUrl(`/users/${userId}/carts/${cartId}/entries/remove/${entry.entryNumber}/${childEntryNumber}`);
        }
        this.http.delete(url, {headers: httpHeaders}).subscribe(
          () => {
            this.reloadActiveCart();
          },
          error => console.error('error:', error)
        );
      });
  }
}
