import { combineLatest, Observable } from "rxjs";
import { ConfiguratorManagerBase } from "../../core/facade/configurator-manager-base.service";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ConfiguratorManagerContract } from "../../core/facade/configurator-manager.contract";
import {
  ConfigurationSelection,
  MatBindingSelection,
  ProductConfigStepSelection
} from "../../core/store/product-configuration.state";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { ProductConfigStepType, ProductConfiguratorButton } from "../../core/models/product-configuration.models";
import { GarageState } from "src/app/spartacus/features/ymm/core/store/garage.state";
import { Store } from "@ngrx/store";
import { ProductConfigurationSelectors } from "../../core/store";

@Injectable({ providedIn: 'root' })
export class MatBindingManagerService implements ConfiguratorManagerContract {
  private selectMatBindingType$ = this.store.select(ProductConfigurationSelectors.selectMatBindingTypeState);

  private selectedMatBindings$ = this.productConfigService.getConfigSelection()
    .pipe(
      map(selection => selection.selections
        ?.filter(s => s.stepType === ProductConfigStepType.MAT_BINDINGS)
      )
    );

  private selectedMats$ = this.productConfigService.getConfigSelection()
    .pipe(
      map(selection => selection.selections
        ?.filter(s => s.stepType === ProductConfigStepType.MAT_STYLES)
      )
    )

  private nextButton$ = combineLatest([
    this.managerBase.nextButton$,
    this.productConfigService.getCurrentSelection()
  ]).pipe(
    map(([btn, selection]) => {
      const matBindingSelection = selection.selections
        ?.find(s => s.stepType === ProductConfigStepType.MAT_BINDINGS) as MatBindingSelection;
      btn.disabled = !matBindingSelection;
      return btn;
    })
  );

  private saveButton$ = combineLatest([
    this.managerBase.saveButton$,
    this.selectMatBindingType$,
    this.selectedMatBindings$,
    this.selectedMats$
  ]).pipe(
    map(([button, matBindingType, matBindings, selectedMats]) => {
      button.disabled = (
        !selectedMats || selectedMats?.length === 0 ||
        (matBindingType.isPremium &&
          (!matBindings.length || !(matBindings[0] as MatBindingSelection).option)) ||
        (!matBindingType.isStandard && !matBindingType.isPremium)
      );
      return button;
    })
  );

  private buttons$ = combineLatest([
    this.managerBase.backButton$,
    this.nextButton$,
    this.saveButton$
  ]);

  constructor(private managerBase: ConfiguratorManagerBase,
    private productConfigService: ProductConfigurationService,
    private store: Store<GarageState>) {
  }

  getButtons(): Observable<ProductConfiguratorButton[]> {
    return this.buttons$;
  }

  getCartPayloadPart(selection: ConfigurationSelection, i: number): string[] {
    const matBinding = selection.selections[i] as MatBindingSelection;
    if (!matBinding || !matBinding.option) {
      return [];
    }
    return [`premiumBinding::${matBinding.option.code}`];
  }

  getPrice(stepSelection: ProductConfigStepSelection) {
    const line = stepSelection as MatBindingSelection;
    return {
      price: line.price?.value ?? 0,
      priceWithDiscount: line.priceWithDiscounts?.value ?? 0,
    };
  }

}
