<section class="product-line-styles">
  <ng-container *ngIf="line$ | async as line">
    <configurator-accordion-item [title]="'productConfiguration.matBinding.title' | cxTranslate"
      [isAnySelected]="isAnySelected$ | async" [numberOfOptions]="line.matBinding.options.length"
      [step]="step">
      <nav class="d-flex mt-4" style="flex-direction: column;">
        <article class="apply-container">
          <div class="buttons">
            <button class="btn btn-secondary" (click)="toggleStandard()" [ngClass]="{'selected': isStandard}">
              {{ 'productConfiguration.matBinding.standardBtn' | cxTranslate }}
            </button>
            <button class="btn btn-secondary" (click)="togglePremium()" [ngClass]="{'selected': isPremium}">
              {{ 'productConfiguration.matBinding.premiumBtn' | cxTranslate }}
            </button>
          </div>
        </article>
        <ng-container *ngIf="isStandard">
          <div class="price">
            <span class="value">{{ 'productConfiguration.matBinding.standardFree' | cxTranslate }}</span>
            <span class="per-line">{{ 'productConfiguration.matBinding.standardCost' | cxTranslate }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="isPremium">
          <div class="price" *ngIf="bindingCost$ | async as price">
            <span class="value">{{ price.formattedValue }}</span>
            <span class="per-line">{{ 'productConfiguration.matBinding.premiumCost' | cxTranslate}}</span>
          </div>
        </ng-container>
        <article class="configurator-list" *ngIf="isPremium">
          <button (click)="selectItem(line.matBinding, opt)" *ngFor="let opt of line.matBinding.options"
            class="configurator-card">

            <!-- Media -->
            <div class="configurator-card__media">
              <cx-media [container]="opt.image"></cx-media>
            </div>

            <section class="configurator-card-neck">
              <div class="configurator-card-neck__container">

                <div class="configurator-card-neck__style">
                  {{ opt.name }}
                </div>

                <div class="configurator-card-neck__price">
                  <config-select-icon [isSelected]="isSelected(opt.code, line.selectedMatBinding)">
                  </config-select-icon>
                </div>
              </div>
            </section>

          </button>
        </article>
      </nav>
    </configurator-accordion-item>
  </ng-container>
</section>
