import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { OrderEntry } from '@spartacus/cart/base/root';
import { I18nModule } from '@spartacus/core';
import { AtMessageModule, MediaModule } from '@spartacus/storefront';

@Component({
  selector: 'item-price-display',
  templateUrl: './item-price-display.component.html',
  styleUrls: ['./item-price-display.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MediaModule,
    I18nModule,
    AtMessageModule
  ]
})
export class ItemPriceDisplayComponent {
  @Input() item: OrderEntry;
}