import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from "@angular/core";
import { map, shareReplay, take } from "rxjs/operators";
import { Price, WindowRef } from "@spartacus/core";
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import { MatBinding, MatBindingOption, ProductConfigStepType } from "../../core/models/product-configuration.models";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { MatBindingSelection } from "../../core/store/product-configuration.state";
import { STEP_INDEX } from "../../dialog/product-config-dialog/product-config-dialog.component";
import { GarageState } from "src/app/spartacus/features/ymm/core/store/garage.state";
import { Store } from "@ngrx/store";
import { ProductConfigurationSelectors } from "../../core/store";

enum BindingType {
  Standard,
  Premium
}

@Component({
  selector: 'mat-binding',
  templateUrl: './mat-binding.component.html',
  styleUrls: ['./mat-binding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})

export class MatBindingComponent {
  isAnySelected$: Observable<boolean>;
  price$ = new BehaviorSubject<Price>(undefined);
  isPremium: boolean = false;
  isStandard: boolean = false;

  matBinding$ = this.service.getConfigurationForCurrentProduct().pipe(
    map(
      (config) =>
        config.steps?.find(
          (s) => s.stepType === ProductConfigStepType.MAT_BINDINGS
        ) as MatBinding
    ),
    shareReplay(1)
  );

  bindingCost$ = this.matBinding$.pipe(
    map(binding => {
      return binding.priceWithDiscounts ? binding.priceWithDiscounts : binding.price;
    })
  );

  selectedMatBinding$ = this.service.getConfigSelection()
  .pipe(
    map(selection => selection.selections
      ?.find(s => s.stepType === ProductConfigStepType.MAT_BINDINGS) as MatBindingSelection
      ),
    shareReplay(1)
  );

  line$ = combineLatest([this.matBinding$, this.selectedMatBinding$])
  .pipe(
    map(([matBinding, selectedMatBinding]) => {  
      return {
        matBinding,
        selectedMatBinding,
      };
    }),
    shareReplay(1)
  );

  constructor(
    private service: ProductConfigurationService,
    private store: Store<GarageState>,
    private winRef: WindowRef,
    @Inject(STEP_INDEX) public step: number
  ) {}

  ngOnInit(): void {
    this.isAnySelected$ = combineLatest([
      this.store.select(ProductConfigurationSelectors.selectMatBindingTypeState).pipe(
        map(state => {
          this.isStandard = state.isStandard;
          this.isPremium = state.isPremium;
          return { isStandard: this.isStandard, isPremium: this.isPremium };
        })
      ),
      this.selectedMatBinding$,
    ]).pipe(
      map(([matBindingState, selection]) => 
        matBindingState.isStandard || 
        (matBindingState.isPremium && selection?.option !== undefined)
      )
    );
  }

  selectItem(matBinding: MatBinding, option: MatBindingOption) {
    this.selectedMatBinding$
      .pipe(take(1))
      .subscribe(selectedMatBinding => {
        if (selectedMatBinding?.option?.code === option.code) {
          if (selectedMatBinding) {  
            this.service.clearMatBinding();
          }         
        } else {      
          if (this.winRef.isBrowser()) {
            const $elements = document.querySelectorAll('.js-show-container');
            $elements.forEach(function ($el) {
              $el.classList.remove('show-container');
            });
          }
          this.service.setMatBinding(matBinding, option);
        }
      });
  }

  isSelected(code: string, selectedMatBinding: MatBindingSelection) : boolean {
    return selectedMatBinding?.option?.code === code;
  }

  toggleStandard() {
    this.toggleBindingType(BindingType.Standard);
  }
  
  togglePremium() {
    this.toggleBindingType(BindingType.Premium);
  }

  toggleBindingType(type: BindingType) {
    const isStandard = type === BindingType.Standard;  
    
    if ((this.isStandard && isStandard) || (this.isPremium && !isStandard)) {
      this.updateBindingType(!this.isStandard, !this.isPremium);
    } else {  
      this.updateBindingType(isStandard, !isStandard);
    }
  }

  private updateBindingType(isStandard: boolean, isPremium: boolean) {
    this.service.updateMatBindingTypeState({
      isStandard: isStandard,
      isPremium: isPremium
    });

    if (this.isStandard) {
      this.service.clearMatBinding();      
    } 
  }
}
