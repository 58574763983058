import { createAction, props, UPDATE } from "@ngrx/store";
import { DescriptionValuePair } from "../../../../../../core/model/common";
import { ProductConfigRequest, ProductConfiguration, ProductLine, ProductLineOption } from "../models/product-configuration.models";
import { Vehicle } from "../../../../ymm/core/models/garage.model";
import {
  AutomotiveLogoSelection,
  ConfigurationSelection,
  ConfigurationSelectionBase,
  EmbroiderySelection,
  LogoSelection,
  MatBindingSelection,
  MatStyleSelection, 
  PersonalizedEmbroiderySelection,
  PersonalizeMatsState,
  MatBindingTypeState,
  ProductConfigurationState
} from "./product-configuration.state";
import { AddonTogglePayload } from "../../configurators/addons/core/addons-model";

const SET_STATE = '[PRODUCT CONFIGURATION] Set State';
const LOAD_SUBMODELS = '[PRODUCT CONFIGURATION] Load Submodels';
const LOAD_SUBMODELS_OK = '[PRODUCT CONFIGURATION] Load Submodels Ok';
const LOAD_OPTIONS = '[PRODUCT CONFIGURATION] Load SubModel Options';
const LOAD_OPTIONS_OK = '[PRODUCT CONFIGURATION] Load SubModel Options Ok';
const SET_SUB_MODEL = '[PRODUCT CONFIGURATION] Set SubModel';

const LOAD_PRODUCT_CONFIGURATION = '[PRODUCT CONFIGURATION] Load Product Configuration';
const LOAD_PRODUCT_CONFIGURATION_OK = '[PRODUCT CONFIGURATION] Load Product Configuration OK';
const LOAD_PRODUCT_CONFIGURATION_LOADING = '[PRODUCT CONFIGURATION] Load Product Configuration Loading';

const SET_CONFIGURATOR = '[PRODUCT CONFIGURATION] Set Configurator';
const SAVE_SELECTION_CONFIG = '[PRODUCT CONFIGURATION] Save Selection Config';
const INITIALIZE_CONFIGURATOR = '[PRODUCT CONFIGURATION] Initialize Configurator';
const CLEAR_CONFIGURATOR = '[PRODUCT CONFIGURATION] Clear Configurator';
const RESET_CONFIGURATOR = '[PRODUCT CONFIGURATION] Reset Configurator';


const TOGGLE_CONFIGURATOR_STYLE = '[PRODUCT CONFIGURATION] Toggle Configurator Style';

const TOGGLE_MATSTYLE_SELECTION = '[PRODUCT CONFIGURATION] Toggle Mat Style Selection';

const CLEAR_MATBINDING_SELECTION = '[PRODUCT CONFIGURATION] Clear Mat Binding Selection';
const SET_MATBINDING_SELECTION = '[PRODUCT CONFIGURATION] Set Mat Binding Selection';

const CLEAR_EMBROIDERY_SELECTION = '[PRODUCT CONFIGURATION] Clear Embroidery Selection';
const SET_EMBROIDERY_SELECTION = '[PRODUCT CONFIGURATION] Set Embroidery Selection';

const SET_PERSONALIZED_EMBROIDERY_SELECTION = '[PRODUCT CONFIGURATION] Set Personalized Embroidery Selection';
const CLEAR_PERSONALIZED_EMBROIDERY_SELECTION = '[PRODUCT CONFIGURATION] Clear Personalized Embroidery Selection';

const SET_LOGO_SELECTION = '[PRODUCT CONFIGURATION] Set Logo Selection';
const CLEAR_LOGO_SELECTION = '[PRODUCT CONFIGURATION] Clear Logo Selection';

const SET_AUTOMOTIVE_LOGO_SELECTION = '[PRODUCT CONFIGURATION] Set Automotive Logo Selection';
const CLEAR_AUTOMOTIVE_LOGO_SELECTION = '[PRODUCT CONFIGURATION] Clear Automotive Logo Selection';

const TOGGLE_APPLIED_AUTOMOTIVE_LOGO_SELECTION = '[PRODUCT CONFIGURATION] Toggle Applied Automotive Logo Selection';

const TOGGLE_ADDON_SELECTION = '[PRODUCT CONFIGURATION] Toggle Addon';

const UPDATE_PERSONALIZED_MATS_STATE = '[PRODUCT CONFIGURATION] Update Personalized Mats State';

const UPDATE_MAT_BINDING_TYPE_STATE = '[PRODUCT CONFIGURATION] Update Mat Binding Type State';


export const setState = createAction(
  SET_STATE,
  props<{ state: ProductConfigurationState }>()
);

export const loadSubmodels = createAction(
  LOAD_SUBMODELS,
  props<{ productCode: string, productLine: string, vehicleCode: string }>()
);

export const loadSubmodelsOk = createAction(
  LOAD_SUBMODELS_OK,
  props<{ productCode: string, productLine: string, vehicleCode: string, submodels: DescriptionValuePair[] }>()
);

export const loadSubModelOptions = createAction(
  LOAD_OPTIONS,
  props<{ productLine: string, subModel: string, vehicle: Vehicle }>()
);

export const loadSubModelOptionsOk = createAction(
  LOAD_OPTIONS_OK,
  props<{
    productLine: string,
    subModel: string,
    options: DescriptionValuePair[],
    vehicle: Vehicle
  }>()
);

export const setSubModel = createAction(
  SET_SUB_MODEL,
  props<{ productLine: string; subModel: string }>()
);

export const set = createAction(
  SET_SUB_MODEL,
  props<{ productLine: string; subModel: string }>()
);

export const loadProductConfiguration = createAction(
  LOAD_PRODUCT_CONFIGURATION,
  props<{ payload: ProductConfigRequest }>()
);

export const loadProductConfigurationOk = createAction(
  LOAD_PRODUCT_CONFIGURATION_OK,
  props<{ config: ProductConfiguration }>()
);

export const loadProductConfigurationLoading = createAction(
  LOAD_PRODUCT_CONFIGURATION_LOADING,
  props<{ loading: boolean }>()
);

export const saveConfigSelection = createAction(
  SAVE_SELECTION_CONFIG,
  props<{ vehicle: Vehicle }>()
);

export const clearConfiguratorState = createAction(
  CLEAR_CONFIGURATOR,
  props<{ productCode: string }>()
);

export const resetConfiguratorState = createAction(
  RESET_CONFIGURATOR,
  props<{ productCode?: string }>()
);

export const initializeConfigurator = createAction(
  INITIALIZE_CONFIGURATOR,
  props<{ productCode: string, activeSelectionConfig?: ConfigurationSelection }>()
);

export const setSelectionProperty = createAction(
  SET_CONFIGURATOR,
  props<{ prop: keyof ConfigurationSelection, value: any }>()
);

export const toggleMatStyle = createAction(
  TOGGLE_MATSTYLE_SELECTION,
  props<{ selection: MatStyleSelection }>()
);

export const setMatBinding = createAction(
  SET_MATBINDING_SELECTION,
  props<{ selection: MatBindingSelection }>()
);

export const clearMatBindingSelection = createAction(
  CLEAR_MATBINDING_SELECTION,
  props<{ selection: ConfigurationSelectionBase }>()
);

export const toggleProductLine = createAction(
  TOGGLE_CONFIGURATOR_STYLE,
  props<{ productLine: ProductLine, option: ProductLineOption }>()
);

export const setEmbroiderySelection = createAction(
  SET_EMBROIDERY_SELECTION,
  props<{ selection: EmbroiderySelection }>()
);

export const clearEmbroiderySelection = createAction(
  CLEAR_EMBROIDERY_SELECTION,
  props<{ selection: ConfigurationSelectionBase }>()
);

export const setPersonalizedEmbroiderySelection = createAction(
  SET_PERSONALIZED_EMBROIDERY_SELECTION,
  props<{ selection: PersonalizedEmbroiderySelection }>()
);

export const clearPersonalizedEmbroiderySelection = createAction(
  CLEAR_PERSONALIZED_EMBROIDERY_SELECTION,
  props<{ selection: ConfigurationSelectionBase }>()
);

export const setLogoSelection = createAction(
  SET_LOGO_SELECTION,
  props<{ selection: LogoSelection }>()
);

export const clearLogoSelection = createAction(
  CLEAR_LOGO_SELECTION,
  props<{ selection: ConfigurationSelectionBase }>()
);

export const setAutomotiveLogoSelection = createAction(
  SET_AUTOMOTIVE_LOGO_SELECTION,
  props<{ selection: AutomotiveLogoSelection }>()
);

export const clearAutomotiveLogoSelection = createAction(
  CLEAR_AUTOMOTIVE_LOGO_SELECTION,
  props<{ selection: ConfigurationSelectionBase }>()
);

export const toggleAppliedAutomotiveLogo = createAction(
  TOGGLE_APPLIED_AUTOMOTIVE_LOGO_SELECTION,
  props<{ selection: AutomotiveLogoSelection }>()
);

export const toggleAddon = createAction(
  TOGGLE_ADDON_SELECTION,
  props<AddonTogglePayload>()
)

export const updatePersonalizedMatsState = createAction(
  UPDATE_PERSONALIZED_MATS_STATE,
  props<PersonalizeMatsState>()
)

export const updateMatBindingTypeState = createAction(
  UPDATE_MAT_BINDING_TYPE_STATE,
  props<MatBindingTypeState>()
)


