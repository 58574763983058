import { ChangeDetectorRef, Component, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { CartItemContextSource, CartItemListComponent } from '@spartacus/cart/base/components';
import {
  ActiveCartFacade,
  Cart,
  CartItemComponentOptions,
  CartItemContext,
  ConsignmentEntry,
  MultiCartFacade,
  OrderEntry,
  PromotionLocation,
  SelectiveCartFacade
} from '@spartacus/cart/base/root';
import { UserIdService } from '@spartacus/core';
import { OutletContextData } from '@spartacus/storefront';
import { ListrakService } from '../../../listrak/core/listrak.service';
import { deepCloning } from "../../../../../tools/tools";
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

interface ItemListContext {
  readonly?: boolean;
  hasHeader?: boolean;
  options?: CartItemComponentOptions;
  cartId?: string;
  items?: OrderEntry[];
  promotionLocation?: PromotionLocation;
  cartIsLoading?: boolean;
}

@Component({
  selector: 'custom-cart-item-list',
  templateUrl: './custom-item-list.component.html',
  providers: [
    CartItemContextSource,
    { provide: CartItemContext, useExisting: CartItemContextSource },
  ],
})
export class CustomItemListComponent
  extends CartItemListComponent
  implements OnChanges, OnInit {

  private cart$: Observable<Cart>;
  private changed = false;

  constructor(
    protected override activeCartService: ActiveCartFacade,
    protected override selectiveCartService: SelectiveCartFacade,
    protected override userIdService: UserIdService,
    protected override multiCartService: MultiCartFacade,
    protected override cd: ChangeDetectorRef,
    protected listrakService: ListrakService,
    private dataLayerService: DataLayerService,
    @Optional() protected override outlet?: OutletContextData<ItemListContext>,
  ) {
    super(activeCartService, selectiveCartService, userIdService, multiCartService, cd, outlet)
  }

  getQuantityControl(control: any) {
    return control.get('quantity');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes[ 'items' ] && changes[ 'items' ].previousValue) {
      if (changes[ 'items' ].currentValue !== changes[ 'items' ].previousValue) {
        this.changed = true;
        this.listrakCartAbandonment()
      }
    }
  }

  // observação
  override ngOnInit(): void {
    this.subscription.add(this.getInputsFromContext());

    this.subscription.add(
      this.userIdService
        ?.getUserId()
        .subscribe((userId) => ( this.userId = userId ))
    );
    this.listrakCartAbandonment();
    this.trackViewCart();
  }

  listrakCartAbandonment() {
    this.listrakService.cartAbandoment(this.items);
  }

  private trackViewCart() {
    // Get the active cart as an observable
    this.cart$ = this.activeCartService.getActive();

    // Subscribe to the cart observable to access the cart data
    this.subscription.add(
      this.cart$.pipe(debounceTime(500)).subscribe(cart => {
        if (cart && cart.entries) {
          if (!window.location.href.includes('/review-order') && !this.changed) {
            this.dataLayerService.viewCartEvent(cart);
          }
        }
      })
    );
  }

  protected override normalizeConsignmentEntries(items: OrderEntry[]) {
    const entries = items.map((consignmentEntry) => {
      const entry = Object.assign(
        {},
        ( consignmentEntry as ConsignmentEntry ).orderEntry
      );
      entry.quantity = consignmentEntry.quantity;
      return entry;
    });

    this._items = this.buildEntryRelationship(entries);
  }

  protected override rerenderChangedItems(items: OrderEntry[]) {
    items = this.buildEntryRelationship(items);
    let offset = 0;
    for (
      let i = 0;
      i - offset < Math.max(items.length, this._items.length);
      i++
    ) {
      const index = i - offset;
      if (
        JSON.stringify(this._items?.[ index ]) !== JSON.stringify(items[ index ])
      ) {
        if (this._items[ index ]) {
          this.form?.removeControl(this.getControlName(this._items[ index ]));
        }
        if (!items[ index ]) {
          this._items.splice(index, 1);
          offset++;
        } else {
          this._items[ index ] = items[ index ];
        }
      }
    }
  }

  private buildEntryRelationship(source: OrderEntry[]): OrderEntry[] {
    const clonedSource = deepCloning(source);
    // automotive logo, personalized embroidery and premium binding using relatedEntries field
    const children1 = clonedSource.filter(e => e.relatedEntries.length > 0).map(e => this.normalizeEntry(e));
    // logo and embroidery using relatedEntry field
    const children2 = clonedSource.filter(e => e.relatedEntry !== undefined && e.relatedEntry !== null && (e.embroidery === null || e.embroidery === undefined));
    const children = children1.concat(children2);

    const baseProductsWithChildren = clonedSource.map((entry, baseIndex) => {
      const relatedChildren = children.filter(child =>
        child.relatedEntries.includes(baseIndex) || child?.relatedEntry === entry.entryNumber
      );

      entry = this.normalizeEmbroideryEntry(entry, clonedSource);

      return {
        ...entry,
        children: relatedChildren
      };
    }).filter(e => e.relatedEntries.length === 0 && !e.relatedEntry && e.relatedEntry !== 0);

    return baseProductsWithChildren;
  }

  private normalizeEntry(entry: OrderEntry): OrderEntry {
    entry.vehicleApp = entry.vehicleApp ?? [];
    return entry;
  }

  private normalizeEmbroideryEntry(entry: OrderEntry, source: OrderEntry[]): OrderEntry {
    entry.vehicleApp = entry.vehicleApp ?? [];
    if ((entry.relatedEntry === undefined || entry.relatedEntry === null) && entry.relatedEntries.length === 0) {
      entry.children = source.filter(sourceEntry =>
        sourceEntry.relatedEntry === entry.entryNumber &&
        !sourceEntry.embroidery);
      const embroideryEntry = source.find(sourceEntry => !!sourceEntry.embroidery && sourceEntry.relatedEntry === entry.entryNumber);
      if (embroideryEntry) {
        const embroideryLine = [
          `Embroidery: ${ embroideryEntry.embroidery.colorName }`,
          `${ embroideryEntry.embroidery.fontName } -`,
          `"${ embroideryEntry.embroidery.text }"`,
          `<br><span class="font-weight-bold text-primary"> ${ embroideryEntry.totalPrice?.formattedValue }</span>`
        ];
        if(embroideryEntry.priceWithDiscount?.value > 0){
          embroideryLine.push(
            `<span class="font-weight-bold">`,
            `Sale ${embroideryEntry?.basePrice?.formattedValue}`,
            `<span class="text-primary font-weight-bold">save ${embroideryEntry?.product?.discount?.formattedValue}`,
            `(${embroideryEntry.product?.discountPercentage}% off)</span>`,
            `</span>`
          );
        }
        entry.vehicleApp.push({
          options: embroideryLine.join(' ')
        });
      }
    }
    return entry;
  }
}
