import { Image, Price, Product } from "@spartacus/core";
import { YMM } from "../../../../ymm/core/models/garage.model";

export interface ProductConfigMetaData {
  productCode: string;
  hasProductLines: boolean;
  color: string;
  subModel: string;
  ymm: YMM;
}

export enum ProductConfigStepType {
  VEHICLE = 'vehicle',
  EMBROIDERY = 'embroidery',
  MAT_STYLES = 'matStyleOptions',
  MAT_BINDINGS = 'matBinding',
  PERSONALIZED_EMBROIDERY = 'personalizedEmbroidery',
  PRODUCT_LINES = 'productLineOptions',
  LOGOS = 'logos',
  AUTOMOTIVE_LOGO = 'automotiveLogo',
  ADDONS = 'addons'
}

export interface MatStyleOption {
  basePartNumber: string;
  bullets: string[];
  illustrationCode: string;
  image: Image,
  price: Price,
  priceWithDiscount: Price;
  sku: string;
  userInterfaceDescription: string;
}

export interface ProductLineOption {
  basePartNumber: string;
  bullets: string[];
  illustrationCode: string;
  image: Image,
  price: Price,
  priceWithDiscount: Price;
  sku: string;
}

export interface MatBindingOption {
  code: string;
  image: Image,
  name: string;
}

export interface ProductConfigStepBase {
  stepType: ProductConfigStepType;
  label?: string;
  hideStep?: boolean;
}

export interface VehicleSelection extends ProductConfigStepBase {
  id: string;
}

export interface MatStyle extends ProductConfigStepBase {
  options: MatStyleOption[]
}

export interface MatBinding extends ProductConfigStepBase {
  code: string;
  options: MatBindingOption[];
  price: Price,
  priceWithDiscounts: Price;
}

export interface MatStyleResponse extends ProductConfigStepBase {
  userInterfaceDescription: string;
  basePartNumber: string;
  bullets: string[];
  illustrationCode: string;
  image: Image,
  price: Price,
  priceWithDiscount: Price;
  sku: string;
}

export interface ProductLine extends ProductConfigStepBase {
  id?: string;
  userInterfaceDescription: string;
  options: ProductLineOption[]
}

export interface EmbroideryFont {
  fontMedias: { font: string, sample: string };
  code: string;
  name: string;
  image: Image;
}

export interface EmbroideryColor {
  code: string;
  name: string;
}

export interface Embroidery extends ProductConfigStepBase {
  note: string;
  image: Image;
  code: string;
  colors: EmbroideryColor[];
  fonts: EmbroideryFont[];
  instructions: string[];
  price: Price;
  priceWithDiscounts: Price;
}

export interface PersonalizedEmbroideryFont {
  code: string;
  image: Image;
  name: string;
}

export interface PersonalizedEmbroideryColor {
  code: string;
  image: Image;
  name: string;
}

export interface PersonalizedEmbroidery extends ProductConfigStepBase {
  code: string;
  fonts: PersonalizedEmbroideryFont[];
  letteringColors: PersonalizedEmbroideryColor[];
  price: Price;
  priceWithDiscounts: Price;
}

export interface LogoItem {
  image: Image;
  logoCode: string;
  name: string;
  price: Price;
  priceWithDiscounts: Price;
}

export interface LogoCategory {
  category: string;
  categoryName: string;
  logos: LogoItem[];
}

export interface Logo extends ProductConfigStepBase {
  categories: LogoCategory[];
  defaultLogo?: LogoItem;
}

export interface AutomotiveLogo extends ProductConfigStepBase {
  logos: LogoItem[];
}

export interface Addons extends ProductConfigStepBase {
  products: Product[];
}

export type ProductConfigurationStep =
  VehicleSelection
  | MatStyle
  | MatBinding
  | ProductLine
  | Embroidery
  | PersonalizedEmbroidery
  | Logo
  | AutomotiveLogo
  | Addons;

export interface ProductConfiguration {
  composedKey: string;
  initialized?: boolean;
  steps: ProductConfigurationStep[];
  metaData: ProductConfigMetaData;
}

export interface ProductConfigRequest {
  productCode: string;
  hasProductLines: boolean;
  year: number;
  make: string;
  subModel: string;
  model: string;
  color: string;
}

export interface ProductConfiguratorButton {
  order: number;
  label: string;
  display: boolean;
  disabled: boolean;
  execute: () => void
  css?: string;
}

export interface ProductConfigurationResponse {
  [ key: string ]: ProductConfigurationStep | ProductConfigurationStep[]
}


export const stepTypeSequence = [
  ProductConfigStepType.VEHICLE,
  ProductConfigStepType.MAT_STYLES,
  ProductConfigStepType.PRODUCT_LINES,
  ProductConfigStepType.EMBROIDERY,
  ProductConfigStepType.PERSONALIZED_EMBROIDERY,
  ProductConfigStepType.LOGOS,
  ProductConfigStepType.AUTOMOTIVE_LOGO,
  ProductConfigStepType.ADDONS,
  ProductConfigStepType.MAT_BINDINGS
];



