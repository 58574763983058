import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {CurrentProductService} from "@spartacus/storefront";
import {delay, distinctUntilKeyChanged, filter, map, shareReplay, switchMap, take, tap} from "rxjs/operators";
import {isNotNullable, Price, Product} from "@spartacus/core";
import {BehaviorSubject, combineLatest, Observable, of} from "rxjs";
import {GarageService} from "../../../../ymm/core/facade/garage.service";
import {
  Embroidery,
  EmbroideryColor,
  Logo,
  LogoItem,
  MatBinding,
  MatBindingOption,
  MatStyleOption,
  PersonalizedEmbroidery, PersonalizedEmbroideryColor, PersonalizedEmbroideryFont,
  ProductConfigMetaData,
  ProductConfigStepType,
  ProductConfiguration,
  ProductLine,
  ProductLineOption,
  VehicleSelection,
} from "../models/product-configuration.models";
import {DescriptionValuePair} from "../../../../../../core/model/common";
import {Vehicle, YMM} from "../../../../ymm/core/models/garage.model";
import {GarageState} from "../../../../ymm/core/store/garage.state";
import {ProductConfigurationActions, ProductConfigurationSelectors} from "../store";
import {
  ConfigurationSelection,
  PersonalizedEmbroiderySelection,
  PersonalizeMatsState,
  MatBindingTypeState,
  SubModelOption
} from "../store/product-configuration.state";
import {buildKeyChainForProductLines} from "./product-configuration.tools";
import {AddonTogglePayload} from "../../configurators/addons/core/addons-model";


@Injectable({ providedIn: 'root' })
export class ProductConfigurationService {

  private subModelOptions: { [ key: string ]: Observable<SubModelOption> } = {};
  private configurations: { [ key: string ]: Observable<ProductConfiguration> } = {};

  private _subModelOptionChange = new BehaviorSubject<string | undefined>(undefined);
  subModelOptionChange$ = this._subModelOptionChange.asObservable();

  private DEFAULT_VEHICLE_SELECTION_STEP: VehicleSelection = {
    id: "VEHICLE",
    stepType: ProductConfigStepType.VEHICLE,
    label: 'Vehicle'
  };

  product$ = this.currentProductService.getProduct()
    .pipe(
      filter(isNotNullable),
      distinctUntilKeyChanged('code'),
      shareReplay(1)
    );

  private _currentConfigComposedKey = new BehaviorSubject<string>('');
  currentConfigComposedKey$ = this._currentConfigComposedKey.asObservable();

  currentConfiguration$ = this.currentConfigComposedKey$
    .pipe(
      switchMap(composedKey => {
        return !composedKey || !this.configurations[ composedKey ]
          ? of({
            composedKey: '',
            metaData: {},
            steps: [ this.DEFAULT_VEHICLE_SELECTION_STEP ]
          } as ProductConfiguration)
          : this.configurations[ composedKey ]
      }),
      shareReplay(1)
    );


  constructor(private store: Store<GarageState>,
              private currentProductService: CurrentProductService,
              private garageService: GarageService) {
  }

  getSubModels(productCode: string, productLine: string, vehicleCode: string)
    : Observable<{ productLine: string, submodels: DescriptionValuePair[] }> {
    return this.store.select(ProductConfigurationSelectors.selectSubModels(productLine, vehicleCode))
      .pipe(
        tap(subModels => {
          if (!subModels) {
            this.store.dispatch(
              ProductConfigurationActions.loadSubmodels({ productCode, productLine, vehicleCode })
            );
          }
          return subModels;
        })
      )
  }

  getSubModelOptions(productLine: string, subModel: string, vehicle: Vehicle): Observable<SubModelOption> {
    const key = `${ vehicle.code }__${ productLine }__${ subModel }`;
    if (!this.subModelOptions[ key ]) {
      this.loadSubModelOption(productLine, subModel, vehicle);
      this.subModelOptions[ key ] = this.store.select(
        ProductConfigurationSelectors.selectSubModelOptions(productLine, subModel, vehicle)
      );
    }
    return this.subModelOptions[ key ]
  }

  loadSubModelOption(productLine: string, subModel: string, vehicle: Vehicle): void {
    this.store.dispatch(
      ProductConfigurationActions.loadSubModelOptions({ productLine, subModel, vehicle })
    );
  }

  getCurrentSelection(): Observable<ConfigurationSelection> {
    return this.store.select(ProductConfigurationSelectors.selectActiveSelection);
  }

  getConfiguration(product: Product, color: string, subModel: string, ymm: YMM)
    : Observable<ProductConfiguration> {
    const metaData: ProductConfigMetaData = {
      productCode: product.code,
      hasProductLines: product.hasAdditionalProductLines,
      color,
      subModel,
      ymm
    };
    const composedKey = buildKeyChainForProductLines(metaData);
    if (!this.configurations[ composedKey ]) {
      this.store.dispatch(
        ProductConfigurationActions.loadProductConfiguration({
          payload: {
            hasProductLines: product.hasAdditionalProductLines,
            productCode: product.code,
            color,
            subModel,
            ...ymm
          }
        })
      );
      this.configurations[ composedKey ] = this.store.select(
        ProductConfigurationSelectors.selectConfigurationForProduct(composedKey)
      ).pipe(
        map(config => {
          return {
            initialized: !!config,
            composedKey,
            ...config,
            steps: [ this.DEFAULT_VEHICLE_SELECTION_STEP, ...( config?.steps ?? [] ) ]
          }
        }),
        shareReplay(1)
      )
    }
    this._currentConfigComposedKey.next(composedKey);

    return this.configurations[ composedKey ];
  }

  restoreConfiguratorState(): void {
    this._currentConfigComposedKey.next(null);
  }


  getConfigurationForCurrentProduct(): Observable<ProductConfiguration> {
    return this.currentConfiguration$;
  }


  setConfiguratorProp(prop: keyof ConfigurationSelection, value: any): void {
    this.store.dispatch(
      ProductConfigurationActions.setSelectionProperty({
        prop,
        value
      })
    );
  }

  setSubModelOption(subModelOption: DescriptionValuePair): void {
    this.setConfiguratorProp('subModelOption', subModelOption);
    this._subModelOptionChange.next(subModelOption?.value);
  }

  toggleMatStyle(matStyle: MatStyleOption): void {
    this.store.dispatch(
      ProductConfigurationActions.toggleMatStyle({
        selection: {
          stepType: ProductConfigStepType.MAT_STYLES,
          option: matStyle,
        }
      })
    );
  }

  clearMatBinding(): void {
    this.store.dispatch(
      ProductConfigurationActions.clearMatBindingSelection({
        selection: {   
          stepType: ProductConfigStepType.MAT_BINDINGS
        }
      })
    );
  }

  setMatBinding(matBinding: MatBinding, option: MatBindingOption): void {
    this.store.dispatch(
      ProductConfigurationActions.setMatBinding({
        selection: {          
          stepType: ProductConfigStepType.MAT_BINDINGS,
          price: matBinding.price,
          priceWithDiscounts: matBinding.priceWithDiscounts,
          option,
        }
      })
    );
  }

  toggleProductLine(productLineStep: ProductLine, option: ProductLineOption): void {
    this.store.dispatch(
      ProductConfigurationActions.toggleProductLine({
        productLine: productLineStep,
        option
      })
    );
  }

  toggleAutomotiveLogo(logo: LogoItem, matStyleOption: MatStyleOption): void {
    this.store.dispatch(
      ProductConfigurationActions.toggleAppliedAutomotiveLogo({
        selection: {
          stepType: ProductConfigStepType.AUTOMOTIVE_LOGO,
          matStyleOption,
          logo
        }
      })
    );
  }

  toggleAddon(payload: AddonTogglePayload): void {
    this.store.dispatch(
      ProductConfigurationActions.toggleAddon(payload)
    );
  }

  clearConfiguratorState(productCode?: string): void {
    this.store.dispatch(ProductConfigurationActions.clearConfiguratorState({ productCode }));
  }

  resetConfiguratorState(productCode?: string): void {
    this.store.dispatch(ProductConfigurationActions.resetConfiguratorState({ productCode }));
  }

  clearEmbroidery(): void {
    this.store.dispatch(
      ProductConfigurationActions.clearEmbroiderySelection({
        selection: {   
          stepType: ProductConfigStepType.EMBROIDERY
        }
      })
    );
  }

  setEmbroidery(text: string, fontCode: string, color: EmbroideryColor, embroidery: Embroidery): void {
    this.store.dispatch(
      ProductConfigurationActions.setEmbroiderySelection({
        selection: {
          stepType: ProductConfigStepType.EMBROIDERY,
          text,
          font: embroidery.fonts.find(f => f.code === fontCode),
          price: embroidery.priceWithDiscounts?.value > 0
            ? embroidery.priceWithDiscounts
            : embroidery.price,
          color,
          embroidery
        }
      })
    );
  }

  getConfigSelection(): Observable<ConfigurationSelection> {
    return this.store.select(ProductConfigurationSelectors.selectActiveSelection);
  }


  saveConfigSelector(): void {
    combineLatest([
      this.garageService.getActiveVehicle(),
      this.getConfigurationForCurrentProduct()
    ]).pipe(
      take(1),
      tap(([ _, config ]) => {
        const logoStep = config.steps.find(s => s.stepType === ProductConfigStepType.LOGOS) as Logo;
        if (logoStep?.defaultLogo) {
          this.setLogo(logoStep.defaultLogo);
        }
      }),
      delay(0)
    ).subscribe(([ vehicle, _ ]) => {
      this.store.dispatch(
        ProductConfigurationActions.saveConfigSelection({ vehicle })
      );
    })
  }

  getConfiguratorForProduct(code: string): Observable<ConfigurationSelection> {
    return this.store.select(
      ProductConfigurationSelectors.selectConfiguratorForProduct(code)
    );
  }

  getConfigurationLoading(): Observable<boolean> {
    return this.store.select(ProductConfigurationSelectors.selectConfigurationLoading);
  }


  initializeConfigurator(productCode: string, configurator?: ConfigurationSelection): Observable<ConfigurationSelection> {
    this.store.dispatch(
      ProductConfigurationActions.initializeConfigurator({
        productCode,
        activeSelectionConfig: configurator
      })
    );
    return this.store.select(
      ProductConfigurationSelectors.selectActiveSelection
    );
  }

  clearLogo(): void {
    this.store.dispatch(ProductConfigurationActions.clearLogoSelection({
      selection: {   
        stepType: ProductConfigStepType.LOGOS
      }
    }));
  }

  setLogo(item: LogoItem): void {
    this.store.dispatch(
      ProductConfigurationActions.setLogoSelection({
        selection: {
          item,
          stepType: ProductConfigStepType.LOGOS
        }
      })
    );
  }

  setAutomotiveLogo(item: LogoItem): void {
    this.store.dispatch(
      ProductConfigurationActions.setAutomotiveLogoSelection({
        selection: {
          logo:item,
          stepType: ProductConfigStepType.AUTOMOTIVE_LOGO
        }
      })
    )
  }

  clearAutomotiveLogo(): void {
    this.store.dispatch(
      ProductConfigurationActions.clearAutomotiveLogoSelection({
        selection: {   
          stepType: ProductConfigStepType.AUTOMOTIVE_LOGO
        }
      })
    )
  }

  setPersonalizedEmbroidery(item: PersonalizedEmbroiderySelection): void {
    this.store.dispatch(
      ProductConfigurationActions.setPersonalizedEmbroiderySelection({
        selection: {
          stepType: ProductConfigStepType.PERSONALIZED_EMBROIDERY,
          textLine1: item.textLine1,
          textLine2: item.textLine2,
          color: item.color,
          font: item.font,
          price: item.price,
          embroidery: item.embroidery,
          selectedMatStyles: item.selectedMatStyles
        }
      })
    )
  }

  clearPersonalizedEmbroidery(): void {
    this.store.dispatch((ProductConfigurationActions.clearPersonalizedEmbroiderySelection({
      selection: {   
        stepType: ProductConfigStepType.PERSONALIZED_EMBROIDERY
      }
    })));
  }

  updatePersonalizedMats(item: PersonalizeMatsState): void {
    this.store.dispatch(
      ProductConfigurationActions.updatePersonalizedMatsState(item)
    );
  }

  updateMatBindingTypeState(item: MatBindingTypeState): void {
    this.store.dispatch(
      ProductConfigurationActions.updateMatBindingTypeState(item)
    );
  }
}
