import { inject, Injectable } from "@angular/core";
import { ProductConfigStepType } from "../models/product-configuration.models";
import { ConfigurationSelection, ProductConfigStepSelection } from "../store/product-configuration.state";
import { Product } from "@spartacus/core";
import { LogoManagerService } from "../../configurators/logos/logo-manager.service";
import { ConfiguratorManagerContract } from "./configurator-manager.contract";
import { VehicleManagerService } from "../../configurators/vehicle-selection/vehicle-manager.service";
import { ProductLineManagerService } from "../../configurators/product-line/product-line-manager.service";
import { EmbroideryManagerService } from "../../configurators/embroidery/embroidery-manager.service";
import { AddonsManagerService } from "../../configurators/addons/core/addons-manager.service";
import { ProductDetailsPrice } from "../../../core/models/product-details.models";
import { MatStyleManagerService } from "../../configurators/mat-style/mat-style-manager.service";
import { AutomotiveLogoManagerService } from "../../configurators/automotive-logo/automotive-logo-manager.service";
import { MatBindingManagerService } from "../../configurators/mat-binding/mat-binding-manager.service";
import {
  PersonalizedEmbroideryManagerService
} from "../../configurators/personalized-embroidery/personalized-embroidery-manager.service";

@Injectable({ providedIn: 'root' })
export class ManagerFactoryService {
  private managerMap: { [ key: string ]: ConfiguratorManagerContract } = {
    [ ProductConfigStepType.VEHICLE ]: inject(VehicleManagerService),
    [ ProductConfigStepType.MAT_STYLES]: inject(MatStyleManagerService),
    [ ProductConfigStepType.MAT_BINDINGS]: inject(MatBindingManagerService),
    [ ProductConfigStepType.PRODUCT_LINES ]: inject(ProductLineManagerService),
    [ ProductConfigStepType.EMBROIDERY ]: inject(EmbroideryManagerService),
    [ ProductConfigStepType.PERSONALIZED_EMBROIDERY ]: inject(PersonalizedEmbroideryManagerService),
    [ ProductConfigStepType.LOGOS ]: inject(LogoManagerService),
    [ ProductConfigStepType.AUTOMOTIVE_LOGO]: inject(AutomotiveLogoManagerService),
    [ ProductConfigStepType.ADDONS ]: inject(AddonsManagerService),
  };

  getManagerForStepType(stepType: ProductConfigStepType): ConfiguratorManagerContract {
    return this.managerMap[ stepType ];
  }

  getPrice(product: Product,
           selection: ProductConfigStepSelection[],
           variantPrice?: ProductDetailsPrice): { price: number, priceWithDiscount: number } {
    const price = product.hasAdditionalProductLines
      ? 0
      : variantPrice?.prices?.price
        ? variantPrice.prices.price?.value
        : product.price?.value;
    const priceWithDiscount = product.hasAdditionalProductLines
      ? 0
      : variantPrice?.prices?.priceWithDiscount
        ? variantPrice.prices.priceWithDiscount?.value
        : product.priceWithDiscount?.value;
    const summary = {
      price,
      priceWithDiscount
    };
    if (!selection?.length) {
      return summary;
    }
    for (const stepSelection of selection) {
      const manager = this.managerMap[ stepSelection.stepType ];
      if (manager) {
        const { price, priceWithDiscount } = manager.getPrice(stepSelection);
        summary.price += price;
        summary.priceWithDiscount += priceWithDiscount;
      }
    }
    return summary;
  }

  getCartPayload(selection: ConfigurationSelection): string[] {
    if (!selection?.selections?.length) {
      return [];
    }
    return selection.selections.map((s, i) => {
      const manager = this.managerMap[ s.stepType ];
      if (!manager) {
        return [];
      }
      const p = manager.getCartPayloadPart(selection, i);
      return p;
    }).reduce((p1, p2) => [ ...p2, ...p1 ]);
  }
}
