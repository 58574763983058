import { Component, Input } from '@angular/core';

@Component({
  selector: 'config-select-icon',
  templateUrl: './config-select-icon.component.html',
  styleUrls: ['./config-select-icon.component.scss'],
})
export class ConfigSelectIconComponent {
  @Input() isSelected: boolean = false;
  @Input() label: string = undefined;
}
