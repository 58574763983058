<section class="cover-dialog" style="min-height: unset;">
  <product-lines-dialog-header></product-lines-dialog-header>

  <ng-container *ngIf="components$ | async as components">
      <ng-container *ngFor="let component of components; let i = index">
        <ng-container *ngIf="(currentStep$ | async) === 1; else configSteps">
          <div class="product-config-dialog-content" #container *ngIf="i === 0">
            <ng-container *ngComponentOutlet="components[0].component"></ng-container>
          </div>
        </ng-container>
        <ng-template #configSteps>
          <div class="product-config-dialog-content" #container *ngIf="i !== 0">
            <ng-container *ngComponentOutlet="component.component; injector: component.injector"></ng-container>
          </div>

          <ng-container *ngIf="showPersonalizeMats && component.stepType === 'matStyleOptions'">
            <div class="product-config-dialog-content">
              <personalize-mats [isAutomotiveLogo]="isAutomotiveLogo" [isPersonalizedEmbroidery]="isPersonalizedEmbroidery"></personalize-mats>
            </div>
          </ng-container>
        </ng-template>
      </ng-container>
  </ng-container>

  <product-lines-dialog-footer></product-lines-dialog-footer>

</section>
