<div class="accordion-item">
  <div class="d-flex align-items-center " style="cursor: pointer;" (click)="toggleAccordion()">
    <h6 class="accordion-heading d-inline-block" [ngClass]="{'active': isExpanded}">
      <div class="d-flex justify-content-center align-items-center">
        <span aria-hidden="true" class="accordion-icon mr-2"></span>
        <span class="accordion-heading__highlight  mr-2">
          {{ "productConfiguration.personalizeMats.title" | cxTranslate }}
        </span>
      </div>
    </h6>
    <div class="d-inline-block ml-auto">
      <div class="accordion-options d-flex align-items-center justify-content-center"
           [ngClass]="{'is-selected': isAnySelected}">
        {{ isAnySelected ? 'Selected' : 'Options ' + numOfOptions }}
      </div>
    </div>
  </div>
  <div class="collapse-container js-show-container no-transition" [ngClass]="{'show-container': isExpanded}">
    <div class="instruction">{{ "productConfiguration.personalizeMats.instruction" | cxTranslate }}</div>
    <div class="buttons">
      <button class="btn btn-secondary" *ngIf="isAutomotiveLogo" (click)="toggleLogo()"
              [ngClass]="{'selected': showAutomotiveLogo}">{{ "productConfiguration.personalizeMats.logoBtn" | cxTranslate }}</button>
      <button class="btn btn-secondary" *ngIf="isPersonalizedEmbroidery" (click)="toggleEmbroidery()"
              [ngClass]="{'selected': showPersonalizedEmbroidery}">{{ "productConfiguration.personalizeMats.embroideryBtn" | cxTranslate }}</button>
      <button class="btn btn-secondary" (click)="togglePlain()"
              [ngClass]="{'selected': isPlainMats}">{{ "productConfiguration.personalizeMats.plainBtn" | cxTranslate }}</button>
    </div>
  </div>
</div>
