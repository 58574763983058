import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { Make, Model, Vehicle, VehicleImagePayload, VehicleType, vehicleTypeImageMap, Year, YMM } from "../core/models/garage.model";
import { HttpClient } from "@angular/common/http";
import { OccEndpointsService } from "@spartacus/core";
import { catchError, map } from "rxjs/operators";
import { setRequestYmm } from "../core/facade/ymm.service";

@Injectable({ providedIn: 'root' })
export class GarageAdapter {
  constructor(private http: HttpClient,
              private endpointService: OccEndpointsService) {
  }

  /**
   * Load all Years from the endpoint.
   */
  loadYears(): Observable<Year[]> {
    const url = this.endpointService.buildUrl('garageYears');
    return this.http.get<{ year: Year[ ] }>(url).pipe(
      map(({ year }) => year),
      catchError(error => {
        return throwError({ error })
      })
    );
  }

  /**
   * Get the makes for the provided Year
   * @param year
   */
  getMakes(year: Year): Observable<Make[]> {
    const url = this.endpointService.buildUrl(
      'garageMakes',
      { queryParams: { year } }
    );
    return this.http.get<{ make: Make[ ] }>(url).pipe(
      map(({ make }) => make)
    );
  }

  /**
   * Load the Models for the given Year and Make
   * @param year
   * @param make
   */
  getModels(year: Year, make: Make): Observable<Model[]> {
    const url = this.endpointService.buildUrl(
      'garageModels',
      { queryParams: { year, make } }
    );
    return this.http.get<{ model: Model[ ] }>(url).pipe(
      map(({ model }) => model)
    );
  }

  getVehicleDetails({ year, make, model }: YMM): Observable<Vehicle> {
    const url = this.endpointService.buildUrl(
      'garageImage',
      { queryParams: { year, make, model } }
    );
    return this.http.get<VehicleImagePayload>(url).pipe(
      map((payload) => {
        return this.buildVehicle(year, make, model, payload);
      }),
    );
  }

  updateFit(productCode: string, ymm: YMM): Observable<{ fit: boolean }> {
    const url = this.endpointService.buildUrl('updateFit', {
      queryParams: {
        ...ymm,
        productCode
      }
    });

    return this.http.get<{ fit: boolean }>(url);
  }


  private buildVehicle(
    year: Year,
    make: Make,
    model: Model,
    { url, vehicleType }: VehicleImagePayload): Vehicle {
    vehicleType = vehicleType ?? VehicleType.CAR;
    if (!url) {
      url = vehicleTypeImageMap.get(vehicleType ?? VehicleType.CAR);
    }
    const ymm = { year, make, model };
    return {
      vehicleType,
      ymm,
      code: `${ year }|${ make }|${ model }`,
      name: `${ year } ${ make } ${ model }`,
      path: setRequestYmm(ymm),
      image: url
    }
  }
}
