import {Injectable} from "@angular/core";
import {ConfiguratorManagerContract} from "../../core/facade/configurator-manager.contract";
import {combineLatest, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ProductConfigStepType, ProductConfiguratorButton} from "../../core/models/product-configuration.models";
import {
  ConfigurationSelection,
  PersonalizedEmbroiderySelection,
  ProductConfigStepSelection
} from "../../core/store/product-configuration.state";
import {ConfiguratorManagerBase} from "../../core/facade/configurator-manager-base.service";
import {ProductConfigurationService} from "../../core/facade/product-configuration.service";

@Injectable({ providedIn: 'root' })
export class PersonalizedEmbroideryManagerService implements ConfiguratorManagerContract {
  private nextButton$ = combineLatest([
    this.managerBase.nextButton$,
    this.productConfigService.getCurrentSelection()
  ]).pipe(
    map(([ btn, selection ]) => {
      const logoSelection = selection.selections
        ?.find(s => s.stepType === ProductConfigStepType.PERSONALIZED_EMBROIDERY) as PersonalizedEmbroiderySelection;
      btn.disabled = !logoSelection;
      return btn;
    })
  );

  private saveButton$ = combineLatest([
    this.managerBase.saveButton$,
    this.productConfigService.getCurrentSelection()
  ]).pipe(
    map(([btn, selection]) => {
      const logoSelection = selection.selections
        ?.find(s => s.stepType === ProductConfigStepType.PERSONALIZED_EMBROIDERY) as PersonalizedEmbroiderySelection;
      if (logoSelection?.color === undefined) {
        btn.disabled = false;
      } else if (logoSelection?.selectedMatStyles && logoSelection?.selectedMatStyles.length > 0) {
        btn.disabled = false;
      } else {
        btn.disabled = true;
      }
      return btn;
    })
  )

  private buttons$ = combineLatest([
    this.managerBase.backButton$,
    this.nextButton$,
    this.saveButton$
  ]);
  constructor(private managerBase: ConfiguratorManagerBase,
              private productConfigService: ProductConfigurationService) {
  }

  getButtons(): Observable<ProductConfiguratorButton[]> {
    return this.buttons$;
  }

  getPrice(stepSelection: ProductConfigStepSelection) {
    const embroiderySelection = stepSelection as PersonalizedEmbroiderySelection;
    if (embroiderySelection?.selectedMatStyles?.length > 0) {
      return {
        price: embroiderySelection?.price?.value * embroiderySelection.selectedMatStyles.length,
        priceWithDiscount: embroiderySelection?.price?.value * embroiderySelection.selectedMatStyles.length,
      }
    } else {
      return {
        price: 0,
        priceWithDiscount: 0
      }
    }
  }

  getCartPayloadPart(selection: ConfigurationSelection, i: number): string[] {
    const embroidery = selection.selections?.find(s => s.stepType === ProductConfigStepType.PERSONALIZED_EMBROIDERY) as PersonalizedEmbroiderySelection;
    if (!embroidery) {
      return [];
    }
    return [ `personalizedEmbroidery::${ embroidery.color?.code }::${ embroidery.font?.code }::${ embroidery.textLine1 }::${ embroidery.textLine2 }::basePartNumbers:${ [embroidery.selectedMatStyles.map(s => `${s.sku}|${s.basePartNumber}`)] }`];   
  }
}
