import { Vehicle } from "../../../../ymm/core/models/garage.model";
import { DescriptionValuePair } from "../../../../../../core/model/common";
import { Price, Product, Image, VariantOptionQualifier } from "@spartacus/core";
import {
  Embroidery,
  EmbroideryColor,
  EmbroideryFont,
  LogoItem,
  MatBindingOption, MatStyle,
  MatStyleOption, PersonalizedEmbroidery, PersonalizedEmbroideryColor,
  PersonalizedEmbroideryFont,
  ProductConfigStepType,
  ProductConfiguration,
  ProductLineOption
} from "../models/product-configuration.models";
import { ProductDetailsPrice, QualifierOptionValue } from "../../../core/models/product-details.models";
import { AddonVariant } from "../../configurators/addons/core/addons-model";


export const PRODUCT_CONFIGURATION_KEY = 'product-configuration';


export interface ConfigurationSelectionBase {
  stepType: ProductConfigStepType;
}

export interface MatStyleSelection extends ConfigurationSelectionBase {
  option: MatStyleOption;
  description?: string;
}

export interface MatBindingSelection extends ConfigurationSelectionBase {
  option: MatBindingOption;
  description?: string;
  price: Price,
  priceWithDiscounts: Price;
}

export interface ProductLineSelection extends ConfigurationSelectionBase {
  id?: string;
  description?: string;
  option?: ProductLineOption;
}

export interface EmbroiderySelection extends ConfigurationSelectionBase {
  text: string;
  color: EmbroideryColor;
  font: EmbroideryFont;
  price: Price;
  embroidery: Embroidery;
}

export interface PersonalizedEmbroiderySelection extends ConfigurationSelectionBase {
  textLine1: string;
  textLine2?: string;
  color: PersonalizedEmbroideryColor;
  font: PersonalizedEmbroideryFont;
  price: Price;
  embroidery: PersonalizedEmbroidery;
  selectedMatStyles?: MatStyleOption[]
}

export interface LogoSelection extends ConfigurationSelectionBase {
  item: LogoItem;
}

export interface AutomotiveLogoSelection extends ConfigurationSelectionBase {
  logo: LogoItem;
  matStyleOption?: MatStyleOption;
  selectedMatStyles?: MatStyleOption[]
}

export interface Addon {
  sku: string;
  productBase: string;
  price: ProductDetailsPrice;
  product: Product;
  addonVariants?: AddonVariant[]
}

export interface AddonsSelection extends ConfigurationSelectionBase {
  addons: Addon[];
}

export type ProductConfigStepSelection =
  MatStyleSelection
  | MatBindingSelection
  | ProductLineSelection
  | EmbroiderySelection
  | PersonalizedEmbroiderySelection
  | LogoSelection
  | AutomotiveLogoSelection
  | AddonsSelection;

export interface ConfigurationSelection {
  productCode?: string;
  productLine?: string;
  subModel?:  DescriptionValuePair,
  subModelOption?: DescriptionValuePair;
  vehicle?: Vehicle;
  selections: ProductConfigStepSelection[]
}

export interface SubModelOption {
  vehicle: Vehicle;
  productLine: string;
  subModel: string;
  options: DescriptionValuePair[];
}

export interface ProductConfigurationState {
  productConfigLoading: boolean;
  submodels?: {
    productLine: string;
    vehicleCode: string;
    submodels: DescriptionValuePair[];
  }[];
  subModelOptions?: SubModelOption[];
  activeSelectionConfig?: ConfigurationSelection;
  productConfigurations: {
    [ productCode: string ]: ConfigurationSelection
  };
  configurations?: {
    [ keyChain: string ]: ProductConfiguration;
  };
  personalizeMats?: PersonalizeMatsState,
  matBindingType?: MatBindingTypeState
}

export const productConfigInitialState: ProductConfigurationState = {
  productConfigLoading: false,
  subModelOptions: [],
  activeSelectionConfig: {
    selections: []
  },
  productConfigurations: {},
  configurations: {},
  personalizeMats: {},
  matBindingType: {}
};

export interface PersonalizeMatsState {
  isAutomotiveLogo?: boolean;
  isPersonalizedEmbroidery?: boolean;
  showAutomotiveLogo?: boolean;
  showPersonalizedEmbroidery?: boolean;
  isPlainMats?: boolean;
}

export interface MatBindingTypeState {
  isStandard?: boolean;
  isPremium?: boolean;
}