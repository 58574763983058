<section class="product-line-styles">


  <ng-container *ngIf="line$ | async as line">
    <configurator-accordion-item [title]="'productConfiguration.matStyle.title' | cxTranslate" [isAnySelected]="line.selectedMatStyles?.length > 0" [numberOfOptions]="line.matStyle.options.length" [step]="step">

        <article class="configurator-list">

          <button
          (click)="selectItem(opt)"
          *ngFor="let opt of line.matStyle.options"
          class="configurator-card">

            <!-- Media -->
            <div class="configurator-card__media">
              <cx-media [container]="opt.image"></cx-media>
            </div>

            <!-- Price Area -->
            <section class="configurator-card-neck">
              <div class="configurator-card-neck__container">

                <div class="configurator-card-neck__style">
                  {{ opt.userInterfaceDescription }}
                </div>

                <div class="configurator-card-neck__price">
                  <div class="configurator-card-neck__price--value">
                    {{
                      opt.priceWithDiscount.value
                        ? opt.priceWithDiscount.formattedValue
                        : opt.price?.formattedValue
                    }}
                  </div>
                  <div class="configurator-card-neck__price--sale">
                    Sale
                  </div>
                  <div class="float-right" style="margin-left: auto;">
                    <config-select-icon [isSelected]="isSelected(opt.basePartNumber, line.selectedMatStyles)">
                    </config-select-icon>
                  </div>
                </div>
              </div>
            </section>

            <!-- Features (Bullets) -->
            <section class="configurator-card-feat">
              <h6 class="configurator-card-feat__heading">Features</h6>
              <ul class="configurator-card-feat__list">
                <li *ngFor="let feature of opt.bullets"
                    class="line-card-feat__item">
                  {{ feature }}
                </li>
              </ul>
            </section>

          </button>
        </article>
    </configurator-accordion-item>
  </ng-container>
</section>
