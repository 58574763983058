import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProductConfiguratorStepsService } from '../../core/facade/product-configurator-steps.service';
import { Subscription, combineLatest, zip } from 'rxjs';

@Component({
  selector: 'configurator-accordion-item',
  templateUrl: './configurator-accordion-item.component.html',
  styleUrls: ['./configurator-accordion-item.component.scss'],
})
export class ConfiguratorAccordionItemComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() isAnySelected: boolean;
  @Input() numberOfOptions: number;
  @Input() step?: number;
  @Input() isOpen?: boolean = false;

  transition: boolean = true;
  openStep$ = this.stepsService.openStep$;
  currStep$ = this.stepsService.currentStep$;

  openStep: number | null;
  currentStep: number;
  subscription: Subscription;

  constructor(protected stepsService: ProductConfiguratorStepsService) {}

  ngOnInit(): void {
    if (this.step !== undefined) {
      this.subscription = this.openStep$.subscribe((openStep) => {
        this.openStep = openStep;
      });
      this.subscription = this.currStep$.subscribe((step) => {
        this.currentStep = step;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  toggle() {
    this.transition = false
     if (this.step === undefined) {
      this.isOpen = !this.isOpen;
    } else {
      if (this.openStep !== this.step) {
        this.stepsService.setStep(this.step);
      } else {
        this.stepsService.setOpenStep(null);
      }
    }

    setTimeout(()=>{
      this.transition = true
    }, 400)
  }
  
}
