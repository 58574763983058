<ng-container *ngIf="showPersonalizedEmbroidery$ | async as showPersonalizedEmbroidery">
  <div class="accordion-wrapper">
  <ng-container *ngIf="personalizedEmbroidery$ | async as personalizedEmbroidery">
    <!--Embroidery Color Accordion-->
    <div class="accordion-item">
      <div class="d-flex align-items-center " style="cursor: pointer;" (click)="toggleColorAccordion()">
        <h6 class="accordion-heading d-inline-block" [ngClass]="{'active': isColorExpanded}">
          <div class="d-flex justify-content-center align-items-center">
            <span aria-hidden="true" class="accordion-icon mr-2"></span>
            <span class="accordion-heading__highlight  mr-2">
          {{ "productConfiguration.personalizedEmbroidery.colorTitle" | cxTranslate }}
        </span>
          </div>
        </h6>
        <div class="d-inline-block ml-auto">
          <div class="accordion-options d-flex align-items-center justify-content-center"
               [ngClass]="{'is-selected': (selectedEmbroidery$ | async) !== undefined && (selectedEmbroidery$ | async)?.color !== undefined}">
            {{ (selectedEmbroidery$ | async) !== undefined && (selectedEmbroidery$ | async)?.color !== undefined ? 'Selected' : 'Options ' + personalizedEmbroidery.letteringColors.length }}
          </div>
        </div>
      </div>
      <div class="collapse-container js-show-container no-transition" [ngClass]="{'show-container': isColorExpanded}">
        <nav class="d-flex mt-4" style="flex-direction: column;">
          <div class="instruction">{{ "productConfiguration.personalizedEmbroidery.step2Instruction" | cxTranslate }}</div>
          <article class="configurator-list">
            <button
              (click)="selectColor(color)"
              *ngFor="let color of personalizedEmbroidery.letteringColors"
              class="configurator-card configurator-card__lettering-color">

              <!-- Media -->
              <div class="configurator-card__media">
                <cx-media [container]="color.image"></cx-media>
              </div>

              <section class="configurator-card-neck">
                <div class="configurator-card-neck__container">
                  <div class="configurator-card-neck__title">
                    {{ color.name }}
                  </div>
                  <config-select-icon [isSelected]="color.code === (selectedEmbroidery$ | async)?.color?.code">
                  </config-select-icon>
                </div>
              </section>

            </button>
          </article>
          <div class="logos-divider"></div>
        </nav>
        <ng-container *ngIf="price$ | async as price">
          <section class="configurator-footer d-none">
            <div class="configurator-footer__price">
              {{ price.formattedValue }}
            </div>
          </section>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="appliedColorFontMatStyle$ | async as applied">
      <ng-container *ngIf="applied.selectedEmbroidery?.color">
        <!--Embroidery Font and lines Accordion-->
        <div class="accordion-item">
          <div class="d-flex align-items-center " style="cursor: pointer;" (click)="toggleFontAccordion()">
            <h6 class="accordion-heading d-inline-block" [ngClass]="{'active': isFontExpanded}">
              <div class="d-flex justify-content-center align-items-center">
                <span aria-hidden="true" class="accordion-icon mr-2"></span>
                <span class="accordion-heading__highlight  mr-2">{{ "productConfiguration.personalizedEmbroidery.styleTitle" | cxTranslate }}</span>
              </div>
            </h6>
            <div class="d-inline-block ml-auto">
              <div class="accordion-options d-flex align-items-center justify-content-center"
                   [ngClass]="{'is-selected': applied.selectedEmbroidery.font !== undefined && applied.selectedEmbroidery.textLine1 !== undefined}">
                {{ applied.selectedEmbroidery.font !== undefined && applied.selectedEmbroidery.textLine1 !== undefined ? 'Selected' : 'Options ' + (personalizedEmbroidery$ | async).letteringColors.length }}
              </div>
            </div>
          </div>
          <div class="collapse-container js-show-container no-transition" [ngClass]="{'show-container': isFontExpanded}">
            <nav class="d-flex mt-4" style="flex-direction: column;">
              <div class="instruction">{{ "productConfiguration.personalizedEmbroidery.step3Instruction" | cxTranslate }}</div>
              <article class="configurator-list">
                <button
                  (click)="selectFont(font)"
                  *ngFor="let font of personalizedEmbroidery.fonts"
                  class="configurator-card configurator-card__lettering-style">

                  <!-- Media -->
                  <div class="configurator-card__media">
                    <cx-media [container]="font.image"></cx-media>
                  </div>

                  <section class="configurator-card-neck">
                    <div class="configurator-card-neck__container">
                      <config-select-icon [isSelected]="font.code === (selectedEmbroidery$ | async)?.font?.code">
                      </config-select-icon>
                    </div>
                  </section>

                </button>
              </article>
              <div class="logos-divider"></div>
            </nav>
            <div class="text-block">
              <div class="price" *ngIf="embroideryCost$ | async as price">
                <span class="value">{{ price.formattedValue }}</span>
                <span class="per-line">{{ 'productConfiguration.personalizedEmbroidery.perLine' | cxTranslate}}</span>
              </div>
              <div class="lines" [formGroup]="embroideryForm">
                <div class="line-1">
                  <input required="true" class="form-control" type="text" (blur)="updateLine1()" [(ngModel)]="line1"
                         placeholder="{{ 'productConfiguration.personalizedEmbroidery.line1Placeholder' | cxTranslate }}" formControlName="line1" />
                  <cx-form-errors [control]="embroideryForm.get('line1')"></cx-form-errors>
                </div>
                <div class="line-2">
                  <input required="true" class="form-control" type="text" (blur)="updateLine2()" [(ngModel)]="line2"
                         placeholder="{{ 'productConfiguration.personalizedEmbroidery.line2Placeholder' | cxTranslate }}" formControlName="line2" />
                  <cx-form-errors [control]="embroideryForm.get('line2')"></cx-form-errors>
                </div>
                <div class="instruction">{{ 'productConfiguration.personalizedEmbroidery.textInstruction' | cxTranslate}}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="applied.matStyle && applied.selectedEmbroidery?.textLine1 && applied.selectedEmbroidery?.font && applied.selectedEmbroidery?.color">
        <!--Apply embroidery accordion-->
        <div class="accordion-item">
          <div class="d-flex align-items-center " style="cursor: pointer;" (click)="toggleApplyAccordion()">
            <h6 class="accordion-heading d-inline-block" [ngClass]="{'active': isApplyAccordionExpanded}">
              <div class="d-flex justify-content-center align-items-center">
                <span aria-hidden="true" class="accordion-icon mr-2"></span>
                <span class="accordion-heading__highlight  mr-2">
          {{ "productConfiguration.personalizedEmbroidery.applyTitle" | cxTranslate }}
        </span>
              </div>
            </h6>
            <div class="d-inline-block ml-auto">
              <div class="accordion-options d-flex align-items-center justify-content-center"
                   [ngClass]="{'is-selected': applied?.selectedMatStyles?.length > 0}">
                {{ applied?.selectedMatStyles?.length > 0 ? 'Selected' : 'Options ' + applied.matStyle.length }}
              </div>
            </div>
          </div>
          <div class="collapse-container js-show-container no-transition" [ngClass]="{'show-container': isApplyAccordionExpanded}">
            <nav class="d-flex mt-4" style="flex-direction: column;">
              <article class="apply-container">
                <div class="buttons">
                  <button *ngFor="let matStyle of applied.matStyle" class="btn btn-secondary"
                          (click)="applyEmbroidery(matStyle)"
                          [disabled]="isMatDisabled(matStyle.option.basePartNumber)"
                          [ngClass]="{'selected': isMatSelected(matStyle.option.basePartNumber, applied.selectedMatStyles), 'disabled': isMatDisabled(matStyle.option.basePartNumber)}">
                    {{ matStyle.option.userInterfaceDescription }}
                  </button>
                </div>
              </article>
            </nav>
          </div>
        </div>

      </ng-container>
    </ng-container>
  </ng-container>
  </div>
</ng-container>
