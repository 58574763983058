import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { VehicleSelectionComponent } from "./vehicle-selection/vehicle-selection.component";
import { ProductLineComponent } from "./product-line/product-line.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { I18nModule } from "@spartacus/core";
import { FormErrorsModule, MediaModule } from "@spartacus/storefront";
import { EmbroideryComponent } from "./embroidery/embroidery.component";
import { LogosComponent } from "./logos/logos.component";
import { AddonsConfiguratorModule } from "./addons/addons-configurator.module";
import { YmmFormComponent } from "../../../ymm/components/ymm-form/ymm-form.component";
import { SvgIconComponent } from "../../../../../components/_CUSTOM/svg/components/svg-icon/svg-icon.component";
import { ConfigSelectIconModule } from '../components/config-select-icon/config-select-icon.module';
import { ConfiguratorAccordionItemModule } from '../components/configurator-accordion-item/configurator-accordion-item.module';
import { MatStyleComponent } from './mat-style/mat-style.component';
import { AutomotiveLogoComponent } from './automotive-logo/automotive-logo.component';
import { PersonalizeMatsComponent } from "./personalize-mats/personalize-mats.component";
import { MatBindingComponent } from './mat-binding/mat-binding.component';
import { PersonalizedEmbroideryComponent } from "./personalized-embroidery/personalized-embroidery.component";

@NgModule({
  declarations: [
    VehicleSelectionComponent,
    MatStyleComponent,
    MatBindingComponent,
    ProductLineComponent,
    EmbroideryComponent,
    PersonalizedEmbroideryComponent,
    LogosComponent,
    AutomotiveLogoComponent,
    PersonalizeMatsComponent
  ],
  providers: [
    CurrencyPipe
  ],
  exports: [
    PersonalizeMatsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    YmmFormComponent,
    I18nModule,
    MediaModule,
    SvgIconComponent,
    FormErrorsModule,
    AddonsConfiguratorModule,
    ConfigSelectIconModule,
    ConfiguratorAccordionItemModule,
  ]
})
export class configuratorComponentsModule {
}
