import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {ProductConfigurationService} from "../../core/facade/product-configuration.service";
import {CurrencyPipe} from "@angular/common";
import {ProductConfiguratorStepsService} from "../../core/facade/product-configurator-steps.service";
import {Price, WindowRef} from "@spartacus/core";
import {STEP_INDEX} from "../../dialog/product-config-dialog/product-config-dialog.component";
import {AutomotiveLogo, LogoItem, MatStyleOption, ProductConfigStepType} from "../../core/models/product-configuration.models";
import {BehaviorSubject, combineLatest} from "rxjs";
import {map, shareReplay, take, tap} from "rxjs/operators";
import { AutomotiveLogoSelection, MatStyleSelection } from "../../core/store/product-configuration.state";

@Component({
  selector: 'automotive-logo',
  templateUrl: 'automotive-logo.component.html',
  styleUrls: ['automotive-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AutomotiveLogoComponent {
  price$ = new BehaviorSubject<Price>(undefined);
  showAutomotiveLogo$ = this.stepService.showAutomotiveLogo$;

  constructor(
    private service: ProductConfigurationService,
    private stepService: ProductConfiguratorStepsService,
    private winRef: WindowRef,
    @Inject(STEP_INDEX) public step: number
  ) {}

  selectItem(logo: LogoItem) {
    this.selectedLogo$
      .pipe(take(1))
      .subscribe(selectedLogo => {
        if (selectedLogo?.logo.logoCode === logo.logoCode) {
          this.service.clearAutomotiveLogo();
        } else {
          if (this.winRef.isBrowser()) {
            const $elements = document.querySelectorAll('.js-show-container');
            $elements.forEach(function ($el) {
              $el.classList.remove('show-container');
            });
          }

          this.service.setAutomotiveLogo(logo);
        }
      });
  }

  selectedLogo$ = this.service.getConfigSelection()
  .pipe(
    map(selection => selection.selections
      ?.find(s => s.stepType === ProductConfigStepType.AUTOMOTIVE_LOGO) as AutomotiveLogoSelection
      ),
    shareReplay(1)
  );

  automotiveLogo$ = this.service.getConfigurationForCurrentProduct().pipe(
    map(
      (config) =>
        config.steps?.find(
          (s) => s.stepType === ProductConfigStepType.AUTOMOTIVE_LOGO
        ) as AutomotiveLogo
    ),
    shareReplay(1)
  );

  matStyles$ = this.service.getConfigSelection()
  .pipe(
    map(selection => selection.selections
      ?.filter(s => s.stepType === ProductConfigStepType.MAT_STYLES) as MatStyleSelection[]
    ),
    shareReplay(1),
  );

  appliedLogoMatStyle$ = combineLatest([this.matStyles$, this.selectedLogo$])
  .pipe(
    map(([matStyles, selectedLogo]) => {
      const selectedMatStyles = selectedLogo?.selectedMatStyles;

      return {
        selectedLogo,
        matStyles,
        selectedMatStyles,
      };
    }),
    shareReplay(1)
  );

  applyLogo(matStyle: MatStyleSelection) {
    this.selectedLogo$
      .pipe(take(1))
      .subscribe(selectedLogo => {
        if (selectedLogo) {
          this.service.toggleAutomotiveLogo(selectedLogo.logo, matStyle.option);
        }
      });
  }

  isSelected(basePartNumber: string, selectedMatStyles: MatStyleOption[]) : boolean {
    return selectedMatStyles?.some(s => basePartNumber === s.basePartNumber);
  }

  private selectPrice = (item: LogoItem) => {
    return !item
      ? { value: 0, formattedValue: '$0.00' }
      : item.priceWithDiscounts?.value > 0
      ? item.priceWithDiscounts
      : item.price;
  };
}
