<div class="accordion-item">
 <div class="d-flex align-items-center " (click)="toggle()" style="cursor: pointer;">
    <h6 class="accordion-heading d-inline-block" [ngClass]="{'active': (step !== undefined ? openStep === step : isOpen)}">
     <div class="d-flex justify-content-center align-items-center">
     <span aria-hidden="true" class="accordion-icon mr-2"></span>
     <span class="accordion-heading__highlight  mr-2">
      {{ title}} <span style="color: #222222;" >{{subtitle}}</span> 
     </span>
     </div>
    </h6>
    <div class="d-inline-block ml-auto">
     <div class="accordion-options d-flex align-items-center justify-content-center" [ngClass]="{'is-selected': isAnySelected}">
      {{isAnySelected? 'Selected': numberOfOptions? 'Options ' + numberOfOptions : 'Options'}}
     </div>
    </div>
 </div>
    <div class="collapse-container js-show-container" [ngClass]="{'show-container': (step !== undefined ? openStep === step : isOpen), 'no-transition': !transition}">
      <ng-content ></ng-content>
   </div>
</div>